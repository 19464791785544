import styled from '@emotion/styled';
import Layout from '../components/layout';

const SecurityPolicy = ({ pathContext: { locale } }) => {
  return (
    <Layout locale={locale} showHeaderMenu={true} showFooter={false}>
    <Container>
      <Title>情報セキュリティ方針</Title>
      <Paragraph>
        株式会社ウォンバットテクノロジー（以下「当社」といいます。）は、弊社サービスに伴う企業活動を通じて、当社に関わる全ての人々に信頼・安心される企業を目指します。取り扱うデータはお客様の情報資産であり、情報資産を漏洩、き損、滅失等のリスクから保護することに、役員、従業員以下、全ての従業者を挙げて取り組みます。
      </Paragraph>
      <List>
        <ListItem>
          1. 情報資産を保護するために情報セキュリティ方針を策定し、これに従って業務を行うとともに、情報セキュリティに関する規格要求事項、法規制及び、お客様との契約事項を遵守します。
        </ListItem>
        <ListItem>
          2. 情報セキュリティ体制を確立し、及び目的達成のための枠組み、情報セキュリティに関する権限および責任を明確にします。
        </ListItem>
        <ListItem>
          3. 全ての従業者が情報セキュリティの重要性を認識し、情報資産の適切な扱いを確実にするために、定期的な教育訓練を行います。
        </ListItem>
        <ListItem>
          4. 情報資産リスクの分析・評価を実施する基準を確立し、定期的に情報資産のリスクアセスメントを実施します。
        </ListItem>
        <ListItem>
          5. リスクアセスメント結果に基づき必要かつ適切な対策を講じることで、情報資産の機密性、完全性、可用性を保護し、変化する情報技術や新たな脅威に対応します。
        </ListItem>
        <ListItem>
          6. 情報セキュリティインシデントが発生した際は、速やかに対応するとともに、適切な是正処置を講じます。
        </ListItem>
        <ListItem>
          7. 情報セキュリティマネジメントシステムを継続的に見直し、改善します。
        </ListItem>
      </List>
      <Signature>
        <p>第1.0版 2024年5月21日</p>
        <p>株式会社ウォンバットテクノロジー</p>
        <p>代表取締役 松本 和也</p>
      </Signature>
    </Container>
    </Layout>
  );
};

const Container = styled.div`
  padding: 40px;
  font-family: 'Arial, sans-serif';
  line-height: 1.6;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
`;

const List = styled.ul`
  list-style-type: decimal;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 16px;
  color: #333333;
`;

const Signature = styled.div`
  margin-top: 30px;
  text-align: right;
`;

export default SecurityPolicy;